import { useEffect } from "react";
import { Container } from "react-bootstrap"


export const ImprovePage = () => {

    useEffect(() =>{
        document.title = "Home Fiesta - Mejora tu fiesta";
    })
    return(
        <Container>
            <div>
      <h1>Cómo Mejorar Tu Fiesta</h1>
      <p>
        Organizar una fiesta exitosa requiere un poco de planificación y creatividad. Si estás buscando formas de llevar tu fiesta al siguiente nivel, aquí tienes algunos consejos que te ayudarán a hacer que tu evento sea inolvidable:
      </p>

      <h2>1. Tema de la Fiesta</h2>
      <p>
        Elige un tema interesante para tu fiesta. Ya sea una fiesta de disfraces, una fiesta de luau, una noche de películas al aire libre o cualquier otro tema que te guste. Un tema puede agregar un toque especial y hacer que la fiesta sea más emocionante.
      </p>

      <h2>2. Decoraciones Creativas</h2>
      <p>
        Las decoraciones son clave. Usa tu imaginación y crea un ambiente que coincida con el tema de la fiesta. Globos, guirnaldas, luces y centros de mesa pueden marcar la diferencia.
      </p>

      <h2>3. Música y Entretenimiento</h2>
      <p>
        Prepara una lista de reproducción con canciones animadas y apropiadas para la ocasión. Considera contratar un DJ o un músico en vivo si es posible. Además, planifica actividades o juegos para mantener a tus invitados entretenidos.
      </p>

      <h2>4. Comida y Bebida</h2>
      <p>
        Ofrece una variedad de alimentos y bebidas deliciosos. Considera opciones para todos los gustos, incluyendo opciones vegetarianas y sin gluten si es necesario. No te olvides de las bebidas refrescantes y cócteles para adultos.
      </p>

      <h2>5. Photobooth</h2>
      <p>
        Configura un photobooth con accesorios divertidos para que los invitados se tomen fotos. Esto añadirá un toque de diversión y permitirá a todos llevarse recuerdos de la fiesta.
      </p>

      <h2>6. Regalitos para los Invitados</h2>
      <p>
        Agradece a tus invitados con pequeños regalitos o recuerdos de la fiesta. Pueden ser algo simple, como dulces o pequeños obsequios que se relacionen con el tema de la fiesta.
      </p>

      <p>
        Siguiendo estos consejos, puedes transformar tu fiesta en un evento inolvidable que tus invitados disfrutarán al máximo. ¡Diviértete planificando y mejorando tu próxima celebración!
      </p>
    </div>
        </Container>
    )
}