import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

export const Footer = () => {
    const footerStyles = {
        content:{
            minHeight: '350px',
            maxHeight: '50vh',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        boxed:{
            maxWidth: '50vw'
        }
    }

    return(
        <Container fluid className="direction-column" style={footerStyles.content}>
            <Row style={footerStyles.boxed}>
                <Col lg={12}>
                    <a className="icon-footer" title="Facebook" href="https://www.facebook.com/profile.php?id=100016401518131" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a className="icon-footer" title="Mail" href="mailto:administrador@homefiesta.com.mx" target="_blank" rel="noopener"><FontAwesomeIcon icon={faEnvelope} /></a>
                    <a className="icon-footer" title="WhatsApp" href="tel:5570605611"><FontAwesomeIcon icon={faWhatsapp} /></a>
                </Col>
            </Row>
            <Row style={footerStyles.boxed}>
                <Col lg={12}>
                    <Link className="link-footer-first" to={"/privacy_notice"}> Aviso de Privacidad </Link> |
                    <Link className="link-footer-first" to={"/"}> &copy; 2023 HomeFiesta</Link>
                </Col>
            </Row>
            <Row style={footerStyles.boxed}>
                <Col lg={12}>
                    <Link className="link-footer-second" to={"/cookies"}> Política de Cookies </Link>
                </Col>
            </Row>
        </Container>
    )
}