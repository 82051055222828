import React, { FunctionComponent } from 'react';

interface Coordinate {
  lat: string;
  lng: string;
}

const CoordinateCheck = (
  coordinatesToCheck: Coordinate,
  referenceCoordinates: Coordinate,
  radius: number,
) => {
  const deg2rad = (deg: number): number => deg * (Math.PI / 180);

  const haversine = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number => {
    const R = 6371; // Radio de la Tierra en kilómetros
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const distance = haversine(
    parseFloat(referenceCoordinates.lat),
    parseFloat(referenceCoordinates.lng),
    parseFloat(coordinatesToCheck.lat),
    parseFloat(coordinatesToCheck.lng)
  );

  return distance <= radius;
};

export default CoordinateCheck;
