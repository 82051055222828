import { Col, Container, Row, Image, Button} from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import GoogleMapReact from 'google-map-react';
import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faHeartCircleMinus, faHeartCirclePlus, faLink, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { faFacebook, faInstagram, faInstagramSquare, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import PromotionModel from "../../models/PromotionModel";
import ProviderModel from "../../models/ProviderModel";


export const PromotionPage = () =>{
    const id = useParams()["id"];
    const [promotion,setPromotion] = React.useState<PromotionModel>(new PromotionModel());
    let {getJSON} = useContext(GlobalContext);
    


    const getPromotion = async() => {
        let params = {
            'id': id
        }
        await getJSON("promotion", "getById", params).then((response: any) => {
            if(response.status){
                setPromotion(response.data);
            }

        });
    }

    
    const providerStyles = {
        rowText2:{
            paddingTop: '1.3em',
            color: '#F12E95',
            fontSize: '2.2em',
            fontWeight: 700,
            textAling: 'center'
        }
    }

    useEffect(() => {
        if (promotion?.id === 0 || promotion?.id == undefined || promotion.id == null){
            getPromotion();
        }
    },[id]);


    
    return(
        <Container>

            <Row>
                <Col md={12} style={{maxHeight: '40vh', overflowY: 'hidden'}}>
                    <img src={promotion?.image} width={'100%'} height={'auto'}  />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <span className="heading-providers-promo">{promotion.name}</span>
                    <span className="description-providers-promo">{promotion.description}</span>
                </Col>
            </Row>
            <Row lg={4} className="providers-grid">
                {promotion.providers.map((provider:ProviderModel, index:number) => {
                    return <Col key={index} className="swiper-content-providers">
                    <Link to={"/provider/" + provider.id}>
                        <Image src={provider.url} alt={provider.name} />
                        <span className="title">{provider.name}</span>
                        {provider.description !== undefined &&
                            <div style={{fontSize: '14px',color: '#545454', minHeight: '64px'}} dangerouslySetInnerHTML={{__html: provider.description}}>

                            </div>

                        }
                        <Link to={"/provider/" + provider.id} className="view-more">Ver Más</Link>
                    </Link>
                </Col>
                })}
            </Row>
        </Container>
    )
}