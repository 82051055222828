import { faBell, faCake, faMapPin } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { Container , Col, Row } from "react-bootstrap"


export const AboutUs = () => {

    useEffect(() =>{
        document.title = "Home Fiesta - Acerca de";
    })
    
    const aboutStyles = {
        containerSettings: {
            padding: '1.5em'
        },
        heading1: {
            color: '#F14E95',
            fontSize: '20px'
        },
        text1: {
            color: '#828282',
            fontSize: '17px',
            display: 'flex',
            justifyContent: 'center',
            padding: '1em'
        },
    }

    return(
        <Container style={aboutStyles.containerSettings}>
            <Row md={1} xs={1} lg={1}> 
                <Col style={aboutStyles.heading1}>Nosotros</Col>
            </Row>
            <Row md={1} xs={1} lg={1}> 
                <Col style={aboutStyles.text1}>Conocenos</Col>
            </Row>
            <Row md={2} xs={1} lg={2}> 
                <Col className="About-Texts">
                   <Row md={1} xs={1} lg={1}>
                        <Col className="item-about">
                            <div className="icons">
                                <FontAwesomeIcon icon={faCake} />
                            </div>
                            <div className="text">
                                <span className="heading-2">
                                    MISIÓN
                                </span>
                                <span className="body">
                                Proporcionar a la comunidad, y público en general accesorio, equipos, aditamentos y servicios completos para cualquier fiesta y/o evento.
                                </span>
                            </div>
                        </Col>
                        <Col className="item-about">
                            <div className="icons">
                                <FontAwesomeIcon icon={faBell} />
                            </div>
                            <div className="text">
                                <span className="heading-2">
                                    VISIÓN
                                </span>
                                <span className="body">
                                    Cubrir en nuestras instalaciones en el corto y mediano plazo las espectativas, en servicios, materiales y accesorios de un público que requiere realizar una fiesta o evento.
                                </span>
                            </div>
                        </Col>
                        <Col className="item-about">
                            <div className="icons">
                                <FontAwesomeIcon icon={faMapPin} />
                            </div>
                            <div className="text">
                                <span className="heading-2">
                                    FESTEJA TU VIDA (SLOGAN)            
                                </span>
                                <span className="body">
                                    Cubrir en nuestras instalaciones en el corto y mediano plazo las espectativas, en servicios, materiales y accesorios de un público que requiere realizar una fiesta o evento.
                                </span>
                            </div>
                        </Col>
                   </Row>
                </Col>
                <Col className="About-Video"></Col>
            </Row>

        </Container>
    )
}