import { Container, Row, Col, Form } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useEffect } from "react";


interface AccountInformationPageProps {
    accountDetail: AccountDetailModel;
    editMode: boolean;
    setAccountDetail: (value: AccountDetailModel) => void;
}

const AccountDetail: React.FC<AccountInformationPageProps> = ({ accountDetail, editMode, setAccountDetail }) => {

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setAccountDetail({ ...accountDetail, [name]: value });
    };

    return(
        <>
            <Row>
                <Col>
                    <h4 style={{color: 'rgb(241, 46, 149)'}}>
                    Información Principal
                    </h4>
                </Col>
            </Row>
            <Row md={3} sm={1}>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Nombre del Negocio</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="name"
                                    onChange={handleInputChange}
                                    value={accountDetail.name} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Nombre Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="contact_name"
                                    onChange={handleInputChange}
                                    value={accountDetail.contact_name} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Email Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="username"
                                    value={accountDetail.username} 
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Telefono</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="phone"
                                    onChange={handleInputChange}
                                    value={accountDetail.phone} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>RFC</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    maxLength={13}
                                    className="shadowInput"
                                    name="rfc"
                                    onChange={handleInputChange}
                                    value={accountDetail.rfc} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>CURP</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    maxLength={18}
                                    name="curp"
                                    onChange={handleInputChange}
                                    value={accountDetail.curp} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Nombre Segundo Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="second_contact_name"
                                    onChange={handleInputChange}
                                    value={accountDetail.second_contact_name} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Correo Segundo Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="second_contact_email"
                                    onChange={handleInputChange}
                                    value={accountDetail.second_contact_email} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Nombre Tercer Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="third_contact_name"
                                    onChange={handleInputChange}
                                    value={accountDetail.third_contact_name} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formHorizontalName">
                        <Row>
                            <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                <Form.Label>Correo Tercer Contacto</Form.Label>
                            </Col>
                            <Col md={12}>
                                <Form.Control 
                                    type="text"
                                    className="shadowInput"
                                    name="third_contact_email"
                                    onChange={handleInputChange}
                                    value={accountDetail.third_contact_email} 
                                    disabled={!editMode}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>

            </Row>

            <Row>
                <Col>
                    <h4 style={{color: 'rgb(241, 46, 149)'}}>
                    Ubicación
                    </h4>
                </Col>
            </Row>
            <Row md={3} sm={1}>
             <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Calle</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="street"
                                onChange={handleInputChange}
                                value={accountDetail.street} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Número Exterior</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="street_number"
                                onChange={handleInputChange}
                                value={accountDetail.street_number} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Número Interior</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="suite_number"
                                onChange={handleInputChange}
                                value={accountDetail.suite_number} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Código Postal</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                maxLength={5}
                                name="cp"
                                onChange={handleInputChange}
                                value={accountDetail.cp} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Municpio / Delegación </Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                maxLength={5}
                                name="entity"
                                onChange={handleInputChange}
                                value={accountDetail.entity} 
                                disabled
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Estado </Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                maxLength={5}
                                name="state"
                                onChange={handleInputChange}
                                value={accountDetail.state} 
                                disabled
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
           

        </Row>
        
        </>
    )
}

export default AccountDetail;