import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../context/GlobalContext";
import PromotionModel from "../../models/PromotionModel";

export const PromotionsPage = () =>{
    const [promotions,setPromotions] = React.useState<PromotionModel[]>([]);

    let {getJSON} =  useContext(GlobalContext);


    const getPromotions = async () =>{
        let params = {

        }
        await  getJSON("promotion", "GetAllWithImages", params).then((response: any) => {
            if(response.status){
                setPromotions(response.data);
            }
        });
    }
    useEffect(() => {
        getPromotions();
    },[null]);

    useEffect(() => {
        window.scrollTo(0, 0);
      });
    return(
        <Container >
            <Row>
                <Col lg={12}>
                    <span className="heading-providers">Explora nuestras promociones</span>
                </Col>
                
            </Row>
            <Row lg={4} className="providers-grid">
                {promotions.map((promotion:PromotionModel, index:number) => {
                    return <Col key={index} className="swiper-content-providers">
                    <Link to={"/promotion/" + promotion.id}>
                        <Image src={promotion.image} alt={promotion.name} />
                        <span className="title">{promotion.name}</span>
                        {promotion.description !== undefined &&
                            <div style={{fontSize: '14px',color: '#545454', minHeight: '64px'}} dangerouslySetInnerHTML={{__html: promotion.description}}>

                            </div>

                        }
                        <Link to={"/promotion/" + promotion.id} className="view-more">Ver Más</Link>
                    </Link>
                </Col>
                })}
            </Row>
        </Container>
    )
}