import React, { useContext } from "react";
import { useEffect } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import LoginModel from "../../models/Account/LoginModel";
import { GlobalContext } from "../../context/GlobalContext";
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";


export const LoginPage = () => {
    const [formValidateLogin, setFormValidateLogin] = React.useState(false);
    const [error, setError] = React.useState(""); 
    const [dataLogin, setDataLogin] = React.useState<LoginModel>(new LoginModel());
    let {getJSON} = useContext(GlobalContext);
    const navigate = useNavigate();

    const validateForm = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if(form.checkValidity()) {
           Login();

        }

        setFormValidateLogin(true);
    }

    const Login = () => {
        getJSON("auth", "login", dataLogin).then((response : any) => {
            if(response.status){
                Cookies.set('name', response.data.userData.name, { expires: 7 });
                Cookies.set('providerName', response.data.userData.provider, { expires: 7 });
                Cookies.set('token', response.data.token, {expires: 7});
                navigate("/account");
            }
            else{
                setError(response.error);
            }
        });
    }

    useEffect(()=> {
        document.title = "Home Fiesta - Iniciar Sesión"
    });


    return(
        <Container>
            <Row>
                <Col md={{span: 4, offset: 4}}>
                    <div className="formLogin">
                    <Form noValidate validated={formValidateLogin} onSubmit={validateForm}>
                            <Row>
                                <Col md={12}>
                                    <p style={{textAlign: 'center', paddingBottom: '5px', paddingTop: '20px',color:'rgb(241, 46, 149)', fontSize: '24px'}}>Iniciar Sesión</p>
                                </Col>
                            </Row>
                            <Row md={1} xs={1} lg={1}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Correo*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    placeholder="Usuario..."
                                                    value={dataLogin.username} 
                                                    onChange={ (event: any) => {
                                                        setDataLogin({
                                                            ...dataLogin,
                                                            username: event.target.value
                                                        });
                                                        setFormValidateLogin(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa tu usuario.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalPhone">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Contraseña*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="password"
                                                    className="shadowInput"
                                                    placeholder="Password..."
                                                    value={dataLogin.password} 
                                                    onChange={ (event: any) => {
                                                        setDataLogin({
                                                            ...dataLogin,
                                                            password: event.target.value
                                                        });
                                                        setFormValidateLogin(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa tu contraseña.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            
                                <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p style={{textAlign: 'right'}}> *Datos obligatorios </p>
                                </Col>
                                <Col md={12}>
                                    <p style={{textAlign: 'right'}}> <Link to={"/recovery_password"}>¿Olvidaste tu contraseña?</Link>  </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={6} style={{textAlign: 'center'}}>
                                    <Button style={{backgroundColor: 'rgb(241, 46, 149)'}} type="submit">Iniciar Sesión</Button>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Form>

                    </div>
                </Col>
            </Row>
        </Container>
    )

}