import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap"



export const PrivacyNotice = () => {


    useEffect(() =>{
        document.title = "Home Fiesta - Aviso de Privacidad";
    })
    
    return(
        <Container>
            <Row className="content-privacy" md={1} ls={1} xs={1}>
                <Col className="heading-1">
                    Aviso de Privacidad Home Fiesta
                </Col>
                <Col className="text-1">
                    <span className="text">
                    En <span className="pink-text">HOME FIESTA</span> estamos convencidos que el principal activo son nuestros clientes; es por ello que la seguridad de su información es nuestra prioridad, por lo que la protegemos mediante el uso, aplicación y mantenimiento de altas medidas de seguridad técnicas, físicas y administrativas.<br/><br/>

                    Como nuestro cliente, usted tiene la oportunidad de escoger entre una amplia gama de productos y servicios, sabiendo que sus datos personales estarán protegidos y serán tratados de manera confidencial.
                    </span>
                    <span className="heading-2">
                        Responsables del tratamiento de sus datos personales:
                    </span>
                    <span className="text">
                        Le informamos que los Responsables de recabar y dar tratamiento o utilizar los datos personales que usted nos   proporcione son: <span className="pink-text">HOME FIESTA</span> <br/><br/>

                        individualmente responsable de los datos que recaba en su carácter de Responsable.
                    </span>
                    <span className="heading-2">
                    Domicilio del responsable:
                    </span>
                    <span className="text">
                        Para efectos del presente aviso de privacidad, <span className="pink-text">HOME FIESTA</span> y <span className="pink-text"> Tarjetas HOME FIESTA</span> señalan individualmente como su domicilio el ubicado en CALLE--   CHALCO, ESTADO DE MEXIC , C.P. 56617
                    </span>
                    <span className="heading-2">
                        Finalidades del tratamiento de sus datos personales:
                    </span>
                    <span className="text">
                    Los datos personales que <span className="pink-text">HOME FIESTA</span> recaben, serán utilizados para atender las siguientes finalidades: <br/><br/>

                    a) Aquellas indispensables para el cumplimiento de la relación jurídica, entre cualquiera de los Responsables mencionados anteriormente y usted, las cuales son: (i) Para corroborar su identidad y veracidad de la información proporcionada, incluyendo la de sus beneficiarios, referencias, obligados solidarios, avales o fiadores, según resulte aplicable; (ii) Para integrar expedientes, bases de datos y sistemas necesarios para llevar a cabo las actividades correspondientes; (iii) Para cumplimentar disposiciones de conocimiento del cliente y prevención de lavado de dinero, ya sea para <span className="pink-text">HOME FIESTA</span> o para sus filiales o subsidiarias que formen parte del mismo grupo, conforme lo prevé la legislación respectiva; (iv) para mantener la relación jurídica que se genere; (v) Para reclamar entrega de premios, sistemas de puntuación y otros programas de lealtad, así como en su caso, los procesos de cobranza que pudieran derivar de dicha relación jurídica; (vi) Para llevar a cabo análisis de riesgo y viabilidad de crédito; y cumplir con regulaciones, políticas y medidas de seguridad física y protección civil, tales como sistemas de video vigilancia, acceso a instalaciones y áreas restringidas; (vii) Permitirle el acceso a cualquiera de los inmuebles en los que se encuentren ubicadas sus oficinas o sucursales; (viii) Para realizar las investigaciones y acciones que consideren necesarias, a efecto de comprobar a través de terceros contratados para ello, dependencia u autoridad, la veracidad de los datos que les fueron proporcionados.<br/><br/>

                    b) Aquellas no indispensables para el cumplimiento de dicha relación jurídica. De manera adicional, se podrán utilizar sus datos personales para las siguientes finalidades secundarias: (i) mercadotecnia, publicidad y prospección comercial; (ii) ofrecerle, en su caso, otros productos bancarios o financieros propios o de cualquiera de sus afiliadas, subsidiarias, sociedades controladoras, asociadas, comisionistas o sociedades integrantes del <span className="pink-text">HOME FIESTA</span>; (iii) remitirle promociones de otros bienes o servicios relacionados con los citados productos bancarios o financieros; (iv) hacer de su conocimiento o invitarle a participar en nuestras actividades no lucrativas de compromiso social que tengan como objetivo promover el desarrollo de las personas, a través de proyectos educativos, sociales, ecológicos y culturales; (v) para realizar análisis estadístico, de generación de modelos de información y/o perfiles de comportamiento actual y predictivo, y participar en encuestas, sorteos y promociones; y (vi) para el intercambio de información sobre las transferencias de fondos nacionales en moneda extranjera, así como de transferencias de fondos internacionales que envíen o se reciban.
                    </span>
                    <span className="heading-2">
                        Datos personales que podrán ser recabados y tratados:
                    </span>
                    <span className="text">
                        Las categorías de datos personales que le podrán ser requeridas para ser recabados y que están sujetas a tratamiento son: (i) Datos de identificación; (ii) Datos de contacto (iii) Datos laborales; (iv) Datos académicos o profesionales; (v) Datos migratorios; (vi) Datos patrimoniales y/o financieros; (vii)

                        HOME FIESTA, podrán realizar las investigaciones y acciones que consideren necesarias, a efecto de comprobar directamente o a través de terceros contratados para ello, dependencia u autoridad, la veracidad de los datos que les fueron proporcionados.

                        Le informamos que no utilizamos tecnologías como Cookies y Web Beacons para la identificación de nuestros clientes.
                    </span>
                    <span className="heading-2">
                        Datos personales que podrán ser recabados y tratados:
                    </span>
                    <span className="text">
                    Las categorías de datos personales que le podrán ser requeridas para ser recabados y que están sujetas a tratamiento son: (i) Datos de identificación; (ii) Datos de contacto (iii) Datos laborales; (iv) Datos académicos o profesionales; (v) Datos migratorios; (vi) Datos patrimoniales y/o financieros; (vii) <br /><br />

                    <span className="pink-text">HOME FIESTA</span>, podrán realizar las investigaciones y acciones que consideren necesarias, a efecto de comprobar directamente o a través de terceros contratados para ello, dependencia u autoridad, la veracidad de los datos que les fueron proporcionados. <br /><br />

                    Le informamos que no utilizamos tecnologías como Cookies y Web Beacons para la identificación de nuestros clientes.
                    </span>
                    <span className="heading-2">
                        Medios para limitar el tratamiento (uso o divulgación) de sus datos personales:
                    </span>
                    <span className="text">
                        Usted o su representante legal debidamente acreditado podrá, en cualquier momento, oponerse uso o divulgación de sus datos personales para las finalidades que no sean indispensables para la relación jurídica; misma que dio origen al servicio que hubiese contratado o solicitado, indicándolo en el recuadro denominado "Autorización de uso de información". Esta se encontrará en la solicitud del producto o servicio que suscriba con <span className="pink-text">HOME FIESTA</span> o a través del procedimiento para el ejercicio de los derechos A.R.C.O., el cual se detalla en este Aviso de Privacidad.
                    </span>
                    <span className="heading-2">
                        Transferencia de datos personales:
                    </span>
                    <span className="text">
                        <span className="pink-text">HOME FIESTA</span> podrán transferir sus datos personales a terceros mexicanos o extranjeros cuando la transferencia: (i) esté prevista en una Ley o Tratado de los que México sea parte; (ii) sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común de <span className="pink-text">HOME FIESTA</span>, a una sociedad matriz o a cualquier sociedad del mismo grupo de los responsables que operen bajo los mismos procesos y políticas internas; (iii) sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre <span className="pink-text">HOME FIESTA</span> y el titular. (iv) sea con base en los demás supuestos establecidos en el Artículo 37 de la Ley Federal de Datos Personales en Posesión de los Particulares.
                    </span>
                    <span className="heading-2">
                        Ejercicio de Derechos A.R.C.O.:
                    </span>
                    <span className="text">
                        Usted o su representante legal debidamente acreditado, cuando procedan, los derechos de Acceso, Rectificación, Cancelación u Oposición que la Ley prevé mediante el formato que <span className="pink-text">HOME FIESTA</span> le proporcionen para tales efectos. Este deberá de ser acompañado de los documentos que acrediten la identidad del titular: identificación oficial con fotografía o, en su caso, la representación legal del titular en términos de la legislación común y cualquier otro elemento o documento que facilite la localización de los datos personales. <br /><br />

                        En el caso de solicitudes de rectificación usted deberá indicar las modificaciones a realizarse y aportar la documentación que sustente su petición. <br /><br />

                        Los documentos se deberán presentar en cualquier sucursal de <span className="pink-text">HOME FIESTA</span>, la cual canalizará su solicitud al departamento de datos personales de HOME FIESTA el cual es el responsable de atender las mismas. Este departamento dará trámite a las solicitudes de los titulares para el ejercicio de los derechos A.R.C.O. en un plazo no mayor a 20 (veinte) días hábiles contados a partir de su recepción. Es importante mencionar que el ejercicio de cualquiera de los derechos A.R.C.O. no es requisito previo ni impide el ejercicio de otro derecho. <br /><br />

                        Usted podrá obtener el formato para ejercer sus derechos A.R.C.O. dando clic <a rel="noopener" href="http://intranet.banamex.com/formatos/Servicio-Clientes/Derechos_Arco/OR_30_3754.pdf" target="_blank" >aquí</a> o bien a través de cualquiera de las sucursales de <span className="pink-text">HOME FIESTA</span>. <br /><br />

                        La respuesta a las solicitudes que cumplan con los requisitos antes mencionados podrá ser notificada al titular o su representante legal vía correo electrónico (cuando se proporcione la dirección de correo electrónico) desde la dirección <a href="mailto:administrador@homefiesta.com.mx" >administrador@homefiesta.com.mx</a> o por medio de mensajería; asimismo, se encontrará disponible en cualquiera de las sucursales de <span className="pink-text">HOME FIESTA</span> previa acreditación de identidad por cualquiera de los medios señalados anteriormente. <br /><br />

                        Por cuestiones de seguridad, no se atenderán solicitudes de ejercicio de derechos A.R.C.O. que sean enviadas al correo electrónico antes mencionado, ya que a través de dicho medio es imposible acreditar la personalidad del titular y/o su representante. <br /><br />

                        Así también, le informamos que usted tiene derecho a iniciar un Procedimiento de Protección de Derechos ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales “INAI” (http:// www.inai.org.mx) dentro de los 15 (quince) días hábiles siguientes a la fecha en que reciba la respuesta de <span className="pink-text">HOME FIESTA</span> o en caso de no recibir respuesta, a partir de que concluya el plazo de 20 (veinte) días hábiles contados a partir de la fecha de recepción de su solicitud de ejercicio de derechos. <br /><br />
                    </span>
                    <span className="heading-2">
                        Revocación del consentimiento:
                    </span>
                    <span className="text">
                        Usted o su representante legal debidamente acreditado podrá, en cualquier momento, oponerse al uso o divulgación de sus datos personales para las finalidades que no sean indispensables para la relación jurídica; misma que dio origen al servicio que hubiese contratado o solicitado, indicándolo en el recuadro denominado "Autorización de uso de información", cuando éste se incluya en las solicitudes del producto o servicio que suscriba con <span className="pink-text">HOME FIESTA</span> indicando “Me opongo al uso o divulgación de mis datos personales” o cualquier otra forma que haga evidente la oposición. En caso de que las solicitudes de productos o servicios no contengan el recuadro antes mencionado, usted podrá oponerse a través del formato que tarjetas Banamex y <span className="pink-text">HOME FIESTA</span> ponga a su disposición para tales efectos conforme al procedimiento para el ejercicio de los derechos A.R.C.O., el cual se detalla en este Aviso de Privacidad. <br /><br />

                        Para efectuar dicha revocación, usted podrá acudir a cualquier sucursal para obtener el formato respectivo para que le indiquen los requisitos que debe de presentar.
                    </span>
                    <span className="heading-2">
                        Modificaciones al aviso de privacidad:
                    </span>
                    <span className="text">
                        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de necesidades propias de <span className="pink-text">HOME FIESTA</span>, por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.

                        Cualquier modificación al presente aviso de privacidad le será notificada a través de cualquiera de los siguientes medios: un comunicado por escrito enviado a su domicilio o entregado en cualquier sucursal <span className="pink-text">HOME FIESTA</span>; un mensaje enviado a su correo electrónico o a su teléfono móvil; un mensaje dado a conocer a través de <a href="www.thehomefiesta.com" >www.thehomefiesta.com</a>  o de cualquier medio electrónico que utilice para celebrar operaciones con HOME o bien, a través de mensajes publicados en las sucursales de <span className="pink-text">HOME FIESTA</span> o en periódicos de amplia circulación.
                    </span>

                </Col>
            </Row>
        </Container>
    )
}