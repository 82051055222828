import { Container, Row, Col, Form } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useEffect } from "react";


interface AccountInformationPageProps {
    accountDetail: AccountDetailModel;
    editMode: boolean;
    setAccountDetail: (value: AccountDetailModel) => void;
}

const AccountAditional: React.FC<AccountInformationPageProps> = ({ accountDetail, editMode, setAccountDetail }) => {

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setAccountDetail({ ...accountDetail, [name]: value });
    };

    return(
        <>
        
        <Row md={3} sm={1}>
             <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Sitio WEB</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="website"
                                onChange={handleInputChange}
                                value={accountDetail.website} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Instagram</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="instagram"
                                onChange={handleInputChange}
                                value={accountDetail.instagram} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                    <Row>
                        <Col md={12} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Form.Label>Youtube</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control 
                                type="text"
                                className="shadowInput"
                                name="youtube"
                                onChange={handleInputChange}
                                value={accountDetail.youtube} 
                                disabled={!editMode}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Col>
           

        </Row>
        
        </>
    )
}

export default AccountAditional;