import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper";
import ProviderModel from "../../models/ProviderModel";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import slide1 from "../../assets/providers/slide_1.webp";
import slide2 from "../../assets/providers/slide_2.webp";
import { GlobalContext } from "../../context/GlobalContext";

export const ProvidersPage = () =>{
    const [providers,setProviders] = React.useState<ProviderModel[]>([]);

    let {getJSON} =  useContext(GlobalContext);


    const getProviders = async () =>{
        let params = {

        }
        await  getJSON("provider", "getAllWithImages", params).then((response: any) => {
            if(response.status){
                setProviders(response.data);
            }
        });
    }
    useEffect(() => {
        
        getProviders();
    },[null]);

    useEffect(() => {
        window.scrollTo(0, 0);
      });
    return(
        <Container >
            <Row>
                <Col lg={12}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        className="swiper-provider"
                        modules={[Navigation]}
                        >
                        {providers.map((provider:ProviderModel , index: number) => {
                        {if(provider.in_provider_section){
                            return <SwiperSlide key={index} className="swiper-content-provider">
                                <Image src={"https://api.vivalasfiestas.com/modules/admin/proveedor/" + provider.url} alt={provider.name}/>
                            </SwiperSlide>
                        }}
                        })}
                            
                    </Swiper>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <span className="heading-providers">Explora profesionales de boda por categoría</span>
                </Col>
                
            </Row>
            <Row lg={4} className="providers-grid">
                {providers.map((provider:ProviderModel, index:number) => {
                    return <Col key={index} className="swiper-content-providers">
                    <Link to={"/"}>
                        <Image src={"https://api.vivalasfiestas.com/modules/admin/proveedor/" + provider.url} alt={provider.name} />
                        <span className="title">{provider.name}</span>
                        {provider.servicios !== undefined &&
                            <span className="servicios">{ provider.servicios}</span>
                        }
                        {provider.servicios === undefined &&
                            <div style={{fontSize: '14px',color: '#545454', minHeight: '64px'}} dangerouslySetInnerHTML={{__html: provider.description}}>

                            </div>

                        }
                        <span className="from"> <FontAwesomeIcon icon={faCoins} /> &nbsp; Desde: ${provider.min_amount !== undefined ? provider.min_amount : 0.00}</span>
                        <Link to={"/provider/" + provider.id} className="view-more">Ver Más</Link>
                    </Link>
                </Col>
                })}
            </Row>
        </Container>
    )
}