import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
import { GlobalReducer } from "./GlobalReducer";
import axios from "axios";
import { IAppGlobalProps } from "../interfaces/Context/IAppGlobalProps";
import { IAppGlobalState } from "../interfaces/Context/IAppGlobalState";
import useStorage from "../helpers/useStorage/useStorage";
import { Spinner } from "react-bootstrap";

let baseURL = 'https://api.vivalasfiestas.com/';

let module = "web";
const reqResApi = axios.create({
    baseURL: baseURL,
    timeout: 1000 * 40,
    timeoutErrorMessage: "El servidor demoró más de lo habitual por favor vuelve a intentar"
});

export const GlobalContext = createContext( {} as IAppGlobalProps);
export const GlobalInitialState: IAppGlobalState = {
    token: "",
    username: "",
    permissions: [],
    urlPhoto: "",
    displayName: "",
    spec_text: ""
}

export const GlobalProvider = ({children} : any) => {
    const [ GlobalState, dispatch ] = useReducer(GlobalReducer, GlobalInitialState);
    const [ loading, setLoading ] = useState(false);
    const toast = useRef<any>(null);
    let {getItem} = useStorage();

    const setGlobalState = ( state: IAppGlobalState ) => {
        dispatch({ type: 'setGlobalState', payload: state});
        sessionStorage.setItem("globalState", JSON.stringify(state));
    }

    const getJSON = async (controller: string, action: string = '', params: any, myModule?: string) => {
        let error = "";
        let warning = "";

        let backData: any = {
            status: false,
            data: {}, 
            error: ''
        };

        let myParams = {
            ...params,
            token: GlobalState.token
        }
        
        const paramsJSON = JSON.stringify(myParams);

        setLoading(true);

        try{
            const fetcher = await fetch(`${baseURL}${myModule === undefined ? module : myModule}/${controller}/${action}`, {
                //credentials: "include",
                method: "POST",
                body: paramsJSON
            });

            let response = await fetcher.json();

            if(response.status === undefined){
                error = response.data;
            }
            else if(!response.status){
                if(response.error === -1){
                    warning = "Sesión caducada";
                }
                else if(response.error === "No active session found"){
                    window.location.href = "https://www.puepenarm.com";
                }
                else{
                    error = `${response.error}`;
                }
            }
            else{
                backData = response;
            }

        }
        catch(response) {
            error = `${response}`;
        }
        finally{

            if(warning !== ""){
                showMessage('warn', warning);
            }
            else if (error !== ""){
                showMessage('error', error);
            }
            
            if(error !== ""){
                backData.status = false;
                backData.error = error;
            }

            setLoading(false);
        }

        return backData;
    }

    const sendData = async (controller: string, action: string = '', params: any, myModule?: string, type: 'formData' | 'post' = 'post') => {
        let error = "";
        let warning = "";

        let backData: any = {
            status: true,
            data: {}, 
            error: ''
        };

        setLoading(true);

        if(type === "formData"){
            let myParams: any;
            myParams = params as FormData;
            myParams.append("token", GlobalState.token);

            await reqResApi.post(`${myModule === undefined ? module : myModule}/${controller}/${action}`, myParams, {
                
            })
            .then( (response: any) => {
                
                if(response.status === 200){
    
                    if(response.data.status === undefined){
                        error = response.data;
                    }
                    else if(!response.data.status){
                        if(response.data.error === -1){
                            warning = "Sesión caducada";
                            
                            setGlobalState({
                                token: "",
                                username: "",
                                permissions: [],
                                urlPhoto: "",
                                displayName: "",
                                spec_text: ""
                            });
                        }
                        else{
                            error = `${response.data.error}`;
                        }    
                    }
                    else{
                        backData = response.data;
                    }
                }
            })
            .catch( response => {
                error = `${response}`;
            }).finally(async () => {
    
                if(warning !== ""){
                    showMessage('warn', warning);
                }
                else if (error !== ""){
                    showMessage('error', error);
                }
                
                if(error !== ""){
                    backData.status = false;
                    backData.error = error;
                }
    
                setLoading(false);
            });
        }
        else{
            params.token = GlobalState.token;
            const paramsJSON = JSON.stringify(params);

            await reqResApi.put(`${myModule === undefined ? module : myModule}/${controller}/${action}`, paramsJSON, {
                "headers": {
                    "content-type": "multipart/form-data",
                }
            })
            .then( (response: any) => {
                
                if(response.status === 200){
    
                    if(response.data.status === undefined){
                        error = response.data;
                    }
                    else if(!response.data.status){
                        if(response.data.error === -1){
                            warning = "Sesión caducada";
                            
                            setGlobalState({
                                token: "",
                                username: "",
                                permissions: [],
                                urlPhoto: "",
                                displayName: "",
                                spec_text: ""
                            });
                        }
                        else{
                            error = `${response.data.error}`;
                        }    
                    }
                    else{
                        backData = response.data;
                    }
                }
            })
            .catch( response => {
                error = `${response}`;
            }).finally(async () => {
    
                if(warning !== ""){
                    showMessage('warn', warning);
                }
                else if (error !== ""){
                    showMessage('error', error);
                }
                
                if(error !== ""){
                    backData.status = false;
                    backData.error = error;
                }
    
                setLoading(false);
            });
        }
        
        setLoading(false);

        return backData;
    }


    const isAuthenticated = (): boolean => {
        let result = false;
        
        if(GlobalState.token !== undefined && GlobalState.token !== null && GlobalState.token !== ""){
            result = true;
        }
        return result;
    }

    const getSpecialtyText = (): string =>{
       
        return getItem("spec_text");
    }

    const showMessage = (type: 'success' | 'warn' | 'error', body: string) => {
        if(toast.current){
            console.log(type);
            toast.current.show({
                severity: type, 
                summary: type === "success" ? "Éxito" : (type === "warn" ? "Cuidado" : "Error"), 
                detail: body, 
                life: 10000
            });
        }
    } 

    useEffect(() => {
        let stringGlobalState: string | null = "";
        let objectGS: IAppGlobalState = GlobalState;
        stringGlobalState = sessionStorage.getItem("globalState");
                
        if(stringGlobalState !== null && stringGlobalState !== ""){
            objectGS = JSON.parse(stringGlobalState!);
            setGlobalState(objectGS);
        }
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                setGlobalState: setGlobalState,
                getGlobalState: GlobalState,
                getJSON: getJSON,
                sendData: sendData,
                isAuthenticated: isAuthenticated,
                showMessage: showMessage
            }}
            >
          
            {loading && (
                <div className="loader-torak" style={{position: "absolute", zIndex: 999, height: "100%", width: "100%"}}>
                    <div className="loader-torak-child">
                        <Spinner animation="border" />
                        <p>Cargando</p>
                    </div>
                </div>
            )}
            
            { children }      
        </GlobalContext.Provider>
    );
}