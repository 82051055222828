import { Container, Row, Col, Form } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import PaymentDetailModel from "../../models/Account/PaymentDetailModel";


interface AccountInformationPageProps {
   idProvider: number
}

const AccountPayment: React.FC<AccountInformationPageProps> = ({ idProvider }) => {
    let {getJSON} = useContext(GlobalContext);
    const [payments,setPayments] = useState<PaymentDetailModel[]>([]);
    let lenght = payments.length;
    const getPayments = async() => {
        let params = {
            "idProvider": idProvider
        }
        await getJSON("provider", "accountPayment", params).then((response : any) => {
            if(response.status){
                setPayments(response.data); 
            }

            
        });
    }

    const displayPlan = (acrom: string) =>{ 
        let plan = '';
        switch (acrom) {
            case 'F':
                plan = "Gratuito"
                break;
            case 'CB':
                plan = "Combo Básico"
                break;
            case 'CM':
                plan = "Combo Medio"
                break;
            case 'CS':
                plan = "Combo Silver"
                break;
            case 'CS':
                plan = "Combo Oro"
                break;
            case 'CP':
                plan = "Combo Platino"
                break;
            default:
                break;
        }
        return plan;
    }
    useEffect(() => {
        if(idProvider > 0 ){
            getPayments();
        }
    },[idProvider])
   

    return(
        <Container className="payment-container">
            {payments.length > 0 && 
                payments.map((payment: PaymentDetailModel, index) => (
                    index === 0 ? (
                        <>
                        
                            <Row lg={1} key={index + 'payment'}>
                                <Row>
                                    <h5>Último Pago:</h5>
                                </Row>
                                <Row className="latest-pay" >
                                    <Col>
                                        <a target="_blank" href={payment.file_url}>{payment.created}</a>
                                    </Col>
                                    <Col>
                                        <span>Plan Seleccionado: {displayPlan(payment.plan)}</span>
                                    </Col>
                                    <Col className="status-pay">
                                        <span className={`payment-status ${payment.aproved === 0 ? 'process' : payment.aproved === 1 ? 'verified' : 'declined'}`}>{payment.aproved === 0 ? 'Pendiente de revisión' : payment.aproved === 1 ? 'Verificado' : 'Declinado'}</span>
                                    </Col>
                                </Row>
                            </Row>
                            {payments.length > 1 && (
                                <Row key="header">
                                    <h6>Tus Pagos:</h6>
                                </Row>
                            )}    
                        </>
                        
                       
                    ) : (
                        <Row lg={1} key={index}>
                            <Row className="other-pay" >
                                <Col>
                                    <a target="_blank" href={payment.file_url}>{payment.created}</a>
                                </Col>
                                <Col>
                                    <span>{displayPlan(payment.plan)}</span>
                                </Col>
                                <Col className="status-pay">
                                    <span className={`payment-status ${payment.aproved == 0 ? 'process' : payment.aproved === 1 ? 'verified' : 'declined'}`}>{payment.aproved == 0 ? 'Pendiente de revisión' : payment.aproved === 1 ? 'Verificado' : 'Declinado'}</span>
                                </Col>
                            </Row>
                        </Row>
                    )
                    
                ))
            }
        </Container>
    )
}

export default AccountPayment;