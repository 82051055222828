import {useContext, useState} from 'react';
import { Container, Row, Col, Form, Button, Image} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import RecoveryPasswordModel from '../../models/Recovery';

const RecoveryPasswordPage = () =>{
    const {getJSON, sendData } = useContext(GlobalContext);
    const [formValidatedRecovery, setFormValidateRecovery] = useState(false);
    const [dataRecovery, setDataRecovery] = useState<RecoveryPasswordModel>(new RecoveryPasswordModel());
    const [error, setError] = useState("");
    const [currentStep, setCurrentStep] = useState<"Step1" | "Step2" | "Step3" | "Step4">("Step1");

    const validateMail= (event: any) => {
        const form = event.currentTarget;
        setError('');
        event.preventDefault();
        event.stopPropagation();

        if(form.checkValidity()){
            getJSON("auth", "SendRecoveryPassword", {username: dataRecovery.username}).then((response : any) => {
                if(response.status){
                setCurrentStep('Step2')
                }
                else{
                    setError(response.error);
                }
            });
        }

        setFormValidateRecovery(true); 
    }

    const validateCode = (event: any) => {
        const form = event.currentTarget;
        setError('');
        event.preventDefault();
        event.stopPropagation();

        if(form.checkValidity()){
            getJSON("auth", "ValidateCodeRecovery", {username: dataRecovery.username, code: dataRecovery.code}).then((response : any) => {
                if(response.status){
                setCurrentStep('Step3')
                }
                else{
                    setError(response.error);
                }
            });
        }

        setFormValidateRecovery(true); 
    }

    const sendNewPassword = (event: any) => {
        const form = event.currentTarget;
        setError('');
        event.preventDefault();
        event.stopPropagation();

        if(form.checkValidity()){
            if(dataRecovery.password === dataRecovery.confirmPassword){
                getJSON("auth", "saveNewPassword", {username: dataRecovery.username, password: dataRecovery.password}).then((response : any) => {
                    if(response.status){
                        setCurrentStep('Step4')
                    }
                    else{
                        setError(response.error);
                    }
                });
            }else{
                setError("Las contraseñas no coinciden");
            }
           
        }

        setFormValidateRecovery(true); 
    }
    
    return(
        <Container fluid  style={{paddingBottom: '100px', marginTop: "50px"}}>
            
            <Row style={{paddingTop: '40px'}}>
                <Col md={3}>
                </Col>
                <Col md={6}>
                    {currentStep === 'Step1' && (
                    <div className="formRecovery"> 
                        <Form noValidate validated={formValidatedRecovery} onSubmit={validateMail}>
                            <Row>
                                <Col md={12}>
                                    <p style={{ textAlign: 'center', paddingTop: '20px',paddingBottom: '15px',color:'#F14E95', fontSize: '1.2em' }}> <b>Recupera tu contraseña</b></p>
                                    <p style={{textAlign: 'center', paddingBottom: '10px',color:'black'}}>Ingresa tu correo a continuación</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row>
                                            <Col md={12} style={{textAlign: 'center'}}>
                                                <Form.Label style={{color: 'black', textAlign: 'center', paddingTop: '8px'}}>Correo electrónico</Form.Label>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="email@dominio.com"
                                                    value={dataRecovery.username} 
                                                    className={"shadowInput"}
                                                    onChange={ (event: any) => {
                                                        setDataRecovery({
                                                            ...dataRecovery,
                                                            username: event.target.value
                                                        });
                                                        setFormValidateRecovery(false);
                                                    }}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>   
                            </Row>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={6} style={{textAlign: 'center'}}>
                                    <Button style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}} type="submit">Solicitar Código</Button>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Form>
                    </div>
                    )}

                    {currentStep === 'Step2' &&(
                    <div className="formRecovery"> 
                        <Form noValidate validated={formValidatedRecovery} onSubmit={validateCode}>
                            <Row>
                                <Col md={12}>
                                    <p style={{ textAlign: 'center', paddingTop: '20px',paddingBottom: '15px',color:'#F14E95', fontSize: '1.2em' }}> <b>Recuperación de Contraseña</b></p>
                                    <p style={{textAlign: 'center', paddingBottom: '10px',color:'black'}}>Proceso de recuperación de contraseña.</p>
                                    <p style={{textAlign: 'center', paddingBottom: '10px',color:'black'}}>Envíamos a su dirección de correo un código de verificación, ingreselo a continuación.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'center'}}>
                                                <Form.Label style={{color: 'black', textAlign: 'center', paddingTop: '8px'}}>Código</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="number"      
                                                    placeholder="000000"
                                                    onChange={(event:any) => {
                                                        if(event.target.value <= 999999){
                                                            setDataRecovery({
                                                                ...dataRecovery,
                                                                code: event.target.value
                                                            });
                                                            setFormValidateRecovery(false)
                                                        }
                                                    }}
                                                    value={dataRecovery.code > 0 ? dataRecovery.code : undefined}
                                                    required
                                                    />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>   
                            </Row>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={6} style={{textAlign: 'center'}}>
                                    <Button  type="submit">Validar Código</Button>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Form>
                </div>
                    )}
                    {currentStep === 'Step3' &&(
                    <div className="formRecovery"> 
                        <Form noValidate validated={formValidatedRecovery} onSubmit={sendNewPassword}>
                            <Row>
                                <Col md={12}>
                                    <p style={{ textAlign: 'center', paddingTop: '20px',paddingBottom: '15px',color:'#F14E95', fontSize: '1.2em' }}> <b>Recuperación de Contraseña</b></p>
                                    <p style={{textAlign: 'center', paddingBottom: '10px',color:'black'}}>Proceso de recuperación de contraseña.</p>
                                    <p style={{textAlign: 'center', paddingBottom: '10px',color:'black'}}>Ingrese su nueva contraseña.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'center'}}>
                                                <Form.Label style={{color: 'black', textAlign: 'center', paddingTop: '8px'}}>Contraseña</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="password"      
                                                    placeholder="Contraseña"
                                                    onChange={(event:any) => {
                                                        
                                                        setDataRecovery({
                                                            ...dataRecovery,
                                                            password: event.target.value
                                                        });
                                                        setFormValidateRecovery(false);
                                                        
                                                    }}
                                                    value={dataRecovery.password}
                                                    required
                                                    />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col> 
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'center'}}>
                                                <Form.Label style={{color: 'black', textAlign: 'center', paddingTop: '8px'}}>Confirmar contraseña</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="password"
                                                    placeholder="Confirmar Contraseña"
                                                    className="shadowInput"
                                                    value={dataRecovery.confirmPassword} 
                                                    onChange={ (event: any) => {
                                                        setDataRecovery({
                                                            ...dataRecovery,
                                                            confirmPassword: event.target.value
                                                        });
                                                        setFormValidateRecovery(false);
                                                    }}
                                                    required/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>   
                            </Row>
                            
                            <Row>
                                <Col md={3}></Col>
                                <Col md={6} style={{textAlign: 'center'}}>
                                    <Button type="submit">Guardar Nueva Contraseña</Button>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Form>
                </div>
                    )}

                    {currentStep === 'Step4' && (
                        <div style={{backgroundColor: '#EBE8EC', borderRadius: '1em', padding: '20px'}}>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{color: 'green', textAlign: 'center', fontWeight: '600'}}>¡Su contraseña ha sido cambiada!</p>
                                </Col>
                            </Row>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{textAlign: 'center', fontWeight: '400'}}>Ya puede iniciar sesión con su nueva contraseña.</p>
                                    <p style={{textAlign: 'center', fontWeight: '500'}}>
                                        <Link to={"/login"}>
                                             Ir a inicio de sesión
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    )}
                
                </Col>
                <Col md={3}>
                </Col>
            </Row>
        </Container>
    )
}

export default RecoveryPasswordPage;