import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { RegisterProviderModel } from "../../models/Register/RegisterProviderModel";
import { GlobalContext } from "../../context/GlobalContext";
import { Link } from "react-router-dom";


export const RegisterProviderPage = () =>{
    const [formValidatedRegister, setFormValidateRegister] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [dataRegister, setDataRegister] = React.useState<RegisterProviderModel>(new RegisterProviderModel());
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    let {getJSON} = useContext(GlobalContext);

    const validateForm = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        
        setError('');
        setSuccess('');
        if(form.checkValidity() && dataRegister.acceptTerms) {
           register();
        }else if(!dataRegister.acceptTerms) {
            setError(error + 'Debes aceptar los términos y condiciones')
        }

        setFormValidateRegister(true);
    }

    const register = () =>{
        setLoading(true);
        getJSON("provider", "register", dataRegister).then((response : any) => {
            if(response.status){
                setDataRegister(new RegisterProviderModel());
                setSuccess("SU CUENTA ESTÁ EN PROCESO DE ACTIVACIÓN, SE ENVIARÁ UN CORREO, EN UN LAPSO, NO MAYOR A 24 HORAS, AL CORREO PROPORCIONADO CON INDICACIONES PARA CONTINUAR CON SU REGISTRO, Por favor revise su correo.");
                setFormValidateRegister(false);
            }
            else{
                setError(response.error);
            }
        });
        setLoading(false);
    }

    useEffect(()=> {
        document.title = "Home Fiesta - Registro Proveedores"
    });

    return(
        <Container>
            <Row>
                <Col  lg={2} md={12}></Col>
                <Col lg={8} md={12}>
                <div className="formRegister">
                        <Form noValidate validated={formValidatedRegister} onSubmit={validateForm}>
                            <Row>
                                <Col md={12}>
                                    <p style={{textAlign: 'center', paddingBottom: '5px', paddingTop: '20px',color:'black'}}>Registro de Proveedores</p>
                                </Col>
                            </Row>
                            <Row md={1} xs={1} lg={1}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre de Contacto*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    placeholder="Nombre..."
                                                    value={dataRegister.contact_name} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            contact_name: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa un nombre.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalPhone">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Teléfono*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="tel"
                                                    className="shadowInput"
                                                    placeholder="5500000000"
                                                    value={dataRegister.phone} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            phone: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa un número telefónico.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Correo electrónico*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="email"
                                                    placeholder="email@dominio.com"
                                                    className="shadowInput"
                                                    value={dataRegister.email} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            email: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa un correo válido.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalPassword">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Contraseña*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="password"
                                                    className="shadowInput"
                                                    value={dataRegister.password} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            password: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa una contraseña.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalBussinessName">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre del negocio*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    placeholder=""
                                                    value={dataRegister.name_bussiness} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            name_bussiness: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa un nombre del negocio.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalDescription">
                                        <Row>
                                            <Col md={3} style={{textAlign: 'right'}}>
                                                <Form.Label>Descripción del negocio (Servicios)*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    as="textarea"
                                                    className="shadowInput"
                                                    placeholder=""
                                                    value={dataRegister.description} 
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            description: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa una descripción del negocio.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="terms_conditions">
                                                <Form.Check
                                                    required
                                                    name="terms"
                                                    label="Acepta los"
                                                    onChange={ (event: any) => {
                                                        setDataRegister({
                                                            ...dataRegister,
                                                            acceptTerms: event.target.value
                                                        });
                                                        setFormValidateRegister(false);
                                                    }} 
                                                    
                                                    id="validationFormik0"
                                                    />
                                                <a href="/terms_conditions" className="pink-text" target="_blank">Terminos y Condiciones</a>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            
                                <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                                <div style={{color: 'green', textAlign: 'center',fontSize: '14px', display: success !== '' ? 'block' : 'none'}}> {success}</div>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p style={{textAlign: 'right'}}> *Datos obligatorios </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={6} style={{textAlign: 'center'}}>
                                    <Button disabled={isLoading} style={{backgroundColor: 'rgb(241, 46, 149)'}} type="submit">
                                    <Spinner
                                        className={ isLoading ? 'spinner-active' : 'spinner-not-active' }
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                    {isLoading ? 'Enviando...' :  'Enviar'}
                                    </Button>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </Container>
    )
}