import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Container, Modal, Row, Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import AccountDetail from "./accountDetail";
import AccountAditional from "./accountAdtitional";
import AccountPayment from "./accountPayment";
import AccountMedia from "./accountMedia";


const AccountPage = () => {
    const navigate = useNavigate();
    const [formValidateAccount, setFormValidateAccount] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState(""); 
    let {getJSON} = useContext(GlobalContext);
    const [accountDetail, setAccountDetail] = useState<AccountDetailModel>(new AccountDetailModel());
    const [handleModal, setHandleModal] = useState(false);

    const getUserData = () => {
        let params = {
            "token_web": Cookies.get("token")
        }
        getJSON("provider", "accountDetail", params).then((response : any) => {
            if(response.status){
                setAccountDetail({...response.data});
                Cookies.set("id",accountDetail.id.toString())
            }
            else{
                Cookies.remove('name');
                Cookies.remove('providerName');
                Cookies.remove('token');
                navigate("/login");
            }

            
        });

        
    }
    const validateAccount = (verifiedAccountDetail: AccountDetailModel) => {
        console.log("hola");
        if( !verifiedAccountDetail.rfc  || 
            !verifiedAccountDetail.curp || 
            !verifiedAccountDetail.second_contact_email || 
            !verifiedAccountDetail.second_contact_name ||
            verifiedAccountDetail.rfc.trim() === "" ||
            verifiedAccountDetail.curp.trim() === "" ||
            verifiedAccountDetail.second_contact_email.trim() === "" ||
            verifiedAccountDetail.second_contact_name.trim() === "" 
        ){
            console.log("Verified:");
            console.log(verifiedAccountDetail);
            navigate("/account_information");

        }
    }

    const validateForm = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if(form.checkValidity()) {
           sendData();

        }

        setFormValidateAccount(true);
    }


    const sendData = ( ) => {

    }

    useEffect( () => {
        if(Cookies.get("name") === "" || Cookies.get("name") === undefined){
            navigate("/login");
        }else{
            getUserData();
        }
    }, [Cookies.get("name")]);

    useEffect(() => {
        if(accountDetail.id){
            validateAccount(accountDetail);
        }
    }, [accountDetail])

    useEffect(()=> {
        document.title = "Home Fiesta - Mi Cuenta"
    });

    return(
        <Container>
            <Row>
                <Col>
                    <h2 style={{color: 'rgb(241, 46, 149)'}}>
                        Mi Cuenta - { accountDetail.name }
                    </h2>
                </Col>
                <Col style={{display: 'flex', justifyContent: 'end'}}>
                    <Button onClick={() => {
                        setEditMode(!editMode)
                    }}> {!editMode && (
                        <>Editar <FontAwesomeIcon icon={faEdit} /></>
                    )}{editMode && (
                        <>Guardar <FontAwesomeIcon icon={faSave} /></>
                    )} </Button>
                </Col>
            </Row>
            <Row>
                <Tab.Container id="left-tabs-example" defaultActiveKey="Detalle">
                    <Row>
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="Detalle">Detalle</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Informacion">Información adicional</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Multimedia">Multimedia</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Pagos">Historial de Pagos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Eventos">Mis Eventos</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="Detalle">
                                <AccountDetail setAccountDetail={setAccountDetail} accountDetail={accountDetail} editMode={editMode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Informacion">
                                <AccountAditional setAccountDetail={setAccountDetail} accountDetail={accountDetail} editMode={editMode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Multimedia">
                                <AccountMedia setAccountDetail={setAccountDetail} accountDetail={accountDetail} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Pagos">
                                <AccountPayment idProvider={accountDetail.id} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Eventos">Mis Eventos</Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>
        </Container>
    )
}

export default AccountPage;