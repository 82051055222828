import { Col, Container, Row, Image, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Logo from "../assets/images/logo-home-fiesta.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect } from "react";
import { MenuItemsModel } from "../models/Home/MenuItemsModel";
import { GlobalContext } from "../context/GlobalContext";
import Cookies from 'js-cookie';

export const Header = () => {
    const [itemsMenu,setItemsMenu] = React.useState<MenuItemsModel[]>([]);
    const [isLogged, setIsLogged] = React.useState(false);
    const navigate = useNavigate();
    let {getJSON} = useContext(GlobalContext);
    let contador = 0;


    const getItemsMenu = () => {
        let params = {

        }
        getJSON("category", "getAll", params).then((response: any) => {
            if(response.status){
                setItemsMenu(response.data);
            }
        });
    }
    const stylesHeader = {
        containerBox:{
            maxWidth: '80vw',
            paddingTop: '0.5em',
            paddingBottom: '1em'
        },
        containerFluid:{
            backgroundColor: '#f5f5f5'
        }
    }

    useEffect(() => {
        if(contador < 5){
            getItemsMenu();
            contador = contador + 1;
        }   
        
    },[null]);

    const LogOut = () => {
        Cookies.remove('name');
        Cookies.remove('providerName');
        setIsLogged(false);
        navigate("/login");

    }

    useEffect(() => {
        if(Cookies.get("name") !== "" && Cookies.get("name") !== undefined){
            setIsLogged(true);
        }else{
            setIsLogged(false);
        }
    },[Cookies.get("name")])
    return(
        <Container  fluid style={stylesHeader.containerFluid}>
            <Container className="header-min" style={stylesHeader.containerBox}>
                <Row>
                    <Col lg={3}>
                        <Link to={"/"}>
                            <Image className="logoheader" src={Logo} alt="Logo"/>
                        </Link>
                            
                    </Col>
                    <Col lg={9}>
                        <Row className="first-row-header">
                            <Col lg={{span: 1,offset:9}}>
                                {!isLogged && (

                                    <Link className="link-first-row" to={"/register_provider"}>Registro</Link>
                                )}
                            </Col>
                            <Col lg={1}>
                                {isLogged && (
                                     <Dropdown>
                                        <Dropdown.Toggle id="dropdown-user">
                                            Hola, {Cookies.get("name")?.split(" ")[0]}
                                        </Dropdown.Toggle>
                                
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/account">Mi Cuenta</Dropdown.Item>
                                            <Dropdown.Item href="/my-events">Mis Eventos</Dropdown.Item>
                                            <Dropdown.Item onClick={() => LogOut()}>Cerrar sesión</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                                {!isLogged && (
                                    <Link className="link-first-row" to={"/login"}>Login</Link>
                                )}
                            </Col>
                        </Row>
                        <Row className="second-row-header">
                            <Col lg={12}>
                                <div>
                                    <Link className="link-second-row" to={"/aboutus"}>Quienes Somos</Link>
                                </div>
                                <div>
                                    <span className="link-second-row" >Servicios</span>
                                    <div className="torak-dropdown-menu">
                                        {itemsMenu.map((item:MenuItemsModel,index:number) => {
                                            if(item.menu_1 === 1){
                                                return<Link key={index} to={"/events/" + item.id}>{item.name}</Link>
                                            }
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <span className="link-second-row">Destacado / Recomendado</span>
                                    <div className="torak-dropdown-menu">
                                        {itemsMenu.map((item:MenuItemsModel,index:number) => {
                                            if(item.menu_2 === 1){
                                                return<Link  key={index} to={"/events/" + item.id}>{item.name}</Link>
                                            }
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <span className="link-second-row">Evento</span>
                                    <div className="torak-dropdown-menu">
                                        {itemsMenu.map((item:MenuItemsModel,index:number) => {
                                            if(item.menu_3 === 1){
                                                return<Link key={index} to={"/events/" + item.id}>{item.name}</Link>
                                            }
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <span className="link-second-row" >Proveedores Especiales</span>
                                    <div className="torak-dropdown-menu">
                                        {itemsMenu.map((item:MenuItemsModel,index:number) => {
                                            if(item.menu_4 === 1){
                                                return<Link  key={index} to={"/events/" + item.id}>{item.name}</Link>
                                            }
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <Link className="link-second-row" to={"/improve"}>Mejora tu Fiesta</Link>
                                </div>
                                <div>
                                    <Link className="link-second-row" to={"/wishlist"}>Organiza tu Evento</Link>
                                </div>
                                <div>
                                    <Link className="link-second-row" to={"/promotions"}>Promociones</Link>
                                </div>
                                <div>
                                    <Link className="link-second-row" to={"/search"}><FontAwesomeIcon icon={faSearch} /></Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        
    )
}