import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Image, Form, Button } from "react-bootstrap"
import ProviderModel from "../../models/ProviderModel";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import 'swiper/css';
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../context/GlobalContext";


export const SearchPage = () => {
    const [searchVal, setSearchVal] = React.useState<string>('');
    const [providers,setProviders] = React.useState<ProviderModel[]>([]);
    const [notResultsFlag, setNotResultsFlag] = React.useState(false);
    const [categories,setCategories] = React.useState<any[]>([]);
    let { getJSON } = useContext(GlobalContext);

    const changeInputSearch = (e:any) => {
        setSearchVal(e.target.value);
    }
    const getElementsBySearch = async(e:any) => {
        e.preventDefault();
        if(searchVal.length > 3){
            let params = {
                keyword: searchVal
            }
            await getJSON("category", "getByKeyword", params).then((response: any) => {
                if(response.status){
                    setCategories(response.data);
                }
            });

            await getJSON("provider", "getByKeyword", params).then((response: any) => {
                if(response.status){
                    setProviders(response.data)
                }
            });

            setNotResultsFlag(true);
        }
    } 

    useEffect(() =>{
        document.title = "Home Fiesta - Búsqueda";
    })

    const homeStyles = {
        boxed:{
            maxWidth: '75vw',
        },
        rowText:{
            paddingTop: '1.3em',
            color: '#F14E95',
            fontSize: '1.5em'
        },
        rowText2:{
            paddingTop: '1.3em',
            color: '#F12E95',
            fontSize: '2.2em',
            fontWeight: 700,
            textAling: 'center'
        }
    }

    return(
        <Container  style={homeStyles.boxed}>
            <Row>
                <Col lg={{span: 10, offset: 1}}>
                    <Form className="searchForm" noValidate onSubmit={getElementsBySearch}>
                        <input minLength={3} onChange={changeInputSearch} type="text" name="search" placeholder="Ingresa tu búsqueda..." value={searchVal} />
                        <Button className="button-search" onClick={getElementsBySearch}><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>
            </Row>
            <Row style={homeStyles.rowText2}>
                {categories.length > 0 && ( 
                    <>
                        <Col lg={12} style={{textAlign: 'center'}}>Categorias Coincidentes <br/></Col>
                        <Col lg={12} className="swiper-providers-container">
                            
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={6}
                                    navigation={true}
                                    className="swiper-home-providers"
                                    modules={[Navigation]}
                                    >
                                    {categories.map((category:any, index:number) => {
                                        const url = category.image_url === null ? 'https://api.vivalasfiestas.com/modules/admin/categoria/default_category.png' : category.image_url;
                                            return <SwiperSlide key={index} className="swiper-content-providers">
                                            <Link to={"/events/" + category.id }>
                                                <Image src={url} alt={category.name} />
                                                <span className="title" style={{justifyContent: 'center', textAlign: 'center'}}>{category.name}</span>
                                                
                                            </Link>
                                        </SwiperSlide>
                                        })}
                                </Swiper>
                        </Col>
                    </>
                )}
            </Row>
            <Row style={homeStyles.rowText2}>
            {providers.length > 0 && ( 
                    <>
                        <Col lg={12} style={{textAlign: 'center'}}>Proveedores Encontrados <br/></Col>
                        <Col lg={12} className="swiper-providers-container">
                            
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={6}
                                    navigation={true}
                                    className="swiper-home-providers"
                                    modules={[Navigation]}
                                    >
                                    {providers.map((provider:any, index:number) => {
                                        const url = provider.url === null ? 'https://api.vivalasfiestas.com/modules/admin/categoria/default_category.png' : provider.url;
                                            return <SwiperSlide key={index} className="swiper-content-providers">
                                            <Link to={"/provider/" + provider.id }>
                                                <Image src={url} alt={provider.name} />
                                                <span className="title" style={{justifyContent: 'center', textAlign: 'center'}}>{provider.name}</span>
                                                
                                            </Link>
                                        </SwiperSlide>
                                        })}
                                </Swiper>
                        </Col>
                    </>
                )}
            </Row>
            <Row className="not-results">
                {providers.length === 0 && categories.length === 0 && notResultsFlag &&(
                    <Col md={12}>
                        Su búsqueda no arrojó resultados, intente con otro término
                    </Col>
                )
                }
            </Row>
        </Container>
    )
}