import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { GlobalProvider } from './context/GlobalContext';
import { BrowserRouter } from 'react-router-dom';
import { Header } from './templates/header.template';
import { Content } from './templates/content.template';
import { Footer } from './templates/footer.template';

function App() {
  return (
    <GlobalProvider >
      <BrowserRouter>
        <Header />
        <Content />
        <Footer />
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
