import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


interface AccountInformationPageProps {
    accountDetail: AccountDetailModel;
    setAccountDetail: (value: AccountDetailModel) => void;
}



const AccountMedia: React.FC<AccountInformationPageProps> = ({ accountDetail, setAccountDetail }) => {
    const plans = {
        'F': {images: 1, videos: 0},
        'CB': {images: 3, videos: 0},
        'CM': {images: 3, videos: 1},
        'CS': {images: 3, videos: 2},
        'CO': {images: 3 , videos: 3},
        'CP': {images: 3 , videos: 3}
    }
    const [limit, setLimit] = useState<{images: number, videos: number}>({images: 0, videos: 0});
    const [planLimit, setPlanLimit] = useState<{images: number, videos: number}>({images: 0, videos: 0});
    const [countMedia,setCountMedia] = useState<{images: number, videos: number}>({images: 0, videos: 0});
    const [file, setFile] = useState<File | undefined>();
    const [isLoading, setLoading] = useState(false);
    const [admitedFiles, setAdmitedFiles] = useState('image/*,video/*');
    let {getJSON,sendData} = useContext(GlobalContext);
    const [uploaded, setUploaded] =  useState(false);

    const getMedia = (idProvider: string) =>{ 
        let params = {
            "idProvider": idProvider
        }
        getJSON("provider", "getAccountMedia", params).then((response : any) => {
            if(response.status){
                setAccountDetail({
                    ...accountDetail,
                    links_media: response.data
                });
                countFiles(response.data);
            }
        });
    }

    const handleOnChangeFile =  (e: React.FormEvent<HTMLInputElement>) => {
      
        const target = e.target as HTMLInputElement & {
          files: FileList;
        }
        let file = target.files?.[0];
        if (file == undefined) return;

        if (file.type.startsWith('video/')) {
            const video = document.createElement('video');
            video.preload = 'metadata';
    
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                if (video.duration > 10) {
                    alert('La duración del video no puede superar los 10 segundos, intenta de nuevo');
                    target.value = '';
                } else {
                    setFile(file);
                }
            };
    
            video.src = URL.createObjectURL(file); 
        } else {
            setFile(file);
        }
        
    }

   
    const countFiles = (data: []) => {
        let countImage = 0;
        let countVideo = 0;
        data.forEach((item: any) => {
            if(item.type == 'Image' ){
                countImage++;
            }else if(item.type == 'Video'){
                countVideo++;
            }
        });

        setCountMedia({images: countImage, videos: countVideo});
    }

    const getFileExtension = (fileName: string): string | null | undefined => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts.pop() : null;
    };

    const submitFile =  () => {
        if ( typeof file === 'undefined' ) return;

        if((limit.images !== 0 && isImageOrVideo(file!) == 'Image') || (limit.videos !== 0 && isImageOrVideo(file!) === 'Video'  ) ){

            const formData: FormData = new FormData(undefined);
            const idProvider = accountDetail.id.toString();
    
            formData.append('file', file);
            formData.append('type', isImageOrVideo(file));
            formData.append('idProvider', idProvider);
    
            sendData("provider", "uploadMedia", formData, undefined, "formData").then((response : any) => {
                if(response.status){
                   setUploaded(true);
                   getMedia(idProvider);
                }else{
                    console.log(response);
                }
               
            });
        }else{
            alert('Lo sentimos, has alcanzado el limite de archivos multimedia para tu plan actual'); 
        }

    }

    const deleteMedia = (id: number) => {
        let params = {
            "id": id
        }
        getJSON("provider", "deleteMedia", params).then((response : any) => {
            if(response.status){
                const idProvider = accountDetail.id.toString();
                getMedia(idProvider);
                
            }
        });
    }


    const isImageOrVideo = (file: File): 'Image' | 'Video' | 'unknown' => {
        const mimeType = file.type;
    
        if (mimeType.startsWith('image/')) {
            return 'Image';
        } else if (mimeType.startsWith('video/')) {
            return 'Video';
        } else {
            return 'unknown';
        }
    };

    const recalc = () => {
        console.log(planLimit);
        console.log(countMedia);
        let newLimitImages = planLimit.images - countMedia.images;
        let newLimitVideos = planLimit.videos - countMedia.videos;

        setLimit({images: newLimitImages, videos: newLimitVideos});
        
    }

    useEffect(() => {
        if(accountDetail.id > 0 && limit.images === 0 && limit.videos === 0){
            var current = plans[accountDetail.plan];
            setLimit(current);
            setPlanLimit(current);
            countFiles(accountDetail.links_media);
            recalc();
        }
    },[accountDetail]);

    useEffect(() => {
        recalc(); 
    }, [countMedia]);

    return(
        <>
            <Row className="info-multimedia">
                <Col md={4}> <span>Archivos multimedia</span></Col>
                <Col md={8}> <span>Aún puedes subir: {limit.images == 1 ? limit.images + ' imagen' : limit.images + ' imágenes' }  y {limit.videos == 1 ? limit.videos + ' video' : limit.videos + ' videos' } </span></Col>
            </Row>
            <Row style={{padding: '2em'}}>
                <Col className="uploadButton">
                    <Form style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.8em'}}>
                        <input
                            id="image"
                            type="file"
                            name="image"
                            accept={admitedFiles}
                            onChange={handleOnChangeFile}
                        />
                        <Button onClick={submitFile} disabled={isLoading} style={{backgroundColor: 'rgb(241, 46, 149)', maxWidth: '200px'}} >
                        <Spinner
                            className={ isLoading ? 'spinner-active' : 'spinner-not-active' }
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                        {isLoading ? 'Subiendo...' :  'Subir'}
                        </Button>
                    </Form>
                </Col>

            </Row>
            <Row className="info-multimedia">
                <span>Tu Multimedia:</span>
            </Row>
            <Row md={4}>
                {accountDetail.links_media.map((media: {id: number, item_order: number, type: string, url: string, status:number}, index: number) => (
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', rowGap: '0.8em'}} key={index + 'media'}>
                        <>
                        {media.type === 'Image' && (
                            <>
                            <span style={{color: '#F14E95', fontWeight: '600'}}>Imagen</span>
                            <img className="media-image" src={accountDetail.path + media.url} style={{maxWidth: '100%', borderRadius: '7%'}} />
                            <span style={{color: '#F14E95', fontWeight: '600'}}>{media.status == 0 ? 'Pendiente' : media.status == 1 ? 'Aceptado' : 'Denegado'}</span>
                            </>
                        )}
                        {media.type === 'Video' && (
                             <>
                                <span style={{color: '#F14E95', fontWeight: '600'}}>Video</span>
                                <video className="media-video" controls muted style={{maxWidth: '100%'}}>
                                    <source src={accountDetail.path + media.url} type={"video/" + getFileExtension(media.url)} />
                                </video>
                                <span style={{color: '#F14E95', fontWeight: '600'}}>{media.status == 0 ? 'Pendiente' : media.status == 1 ? 'Aceptado' : 'Denegado'}</span>
                            </>
                        )}
                            <Button style={{fontSize: '0.85em'}} onClick={( ) => deleteMedia(media.id)}><FontAwesomeIcon icon={faTrash} /> Eliminar</Button>
                        </>
                        
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default AccountMedia;