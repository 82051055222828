import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"



export const TermsConditions = () => {

    useEffect(() =>{
        document.title = "Home Fiesta - Términos y Condiciones";
    })
    return(
        <Container>
            <Row className="content-privacy" md={1} ls={1} xs={1}>
                <Col className="heading-1">
                    TÉRMINOS Y CONDICIONES, de los portales PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM.COM y VIVALASFIESTAS.COM
                </Col>
                <Col className="text-1">
                    <span className="text">
                        A los Usuarios les informamos de los siguientes Términos y Condiciones de Uso y Privacidad, les son aplicables por el simple uso o acceso a cualquiera de las Páginas que integran el portal  “PORTALES PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM.COM  Y VIVA LAS FIESTAS.COM” por lo que entenderemos que los acepta, y acuerda en obligarse en su cumplimiento. <br/><br/>
                    <span className="pink-text">
                        En el caso de que no esté de acuerdo con los Términos y Condiciones de Uso y Privacidad deberá abstenerse de acceder o utilizar el Portal.
                    </span><br/><br/>

                    Los integrantes del PORTALES PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM.COM  Y VIVA LAS FIESTAS.COM y/o sus subsidiarias, controladoras, partes relacionadas y afiliadas (
                       <span className="pink-text">
                         en lo sucesivo, conjunta e indistintamente “PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM”
                        </span> ), 
                    se reservan el derecho de modificar discrecionalmente el contenido del Portal en cualquier momento, sin necesidad de previo aviso.
                    <br/><br/>
                    El usuario entendido como aquella persona que realiza el acceso mediante equipo de cómputo y/o de comunicación (en adelante el “Usuario”), conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Portal o en las bases de datos y/o información que se contenga en el mismo.

                    
                    </span>
                    <span className="heading-2">
                        1. USO Y RESTRICCIONES
                    </span>
                    <span className="text">
                        El acceso o utilización del Portal expresan la adhesión plena y sin reservas del Usuario a los presentes Términos y Condiciones de Uso y Privacidad. A través del Portal, el Usuario se servirá y/o utilizará diversos servicios y contenidos (en lo sucesivo, los "Servicios y Contenidos"), puestos a disposición de los Usuarios por PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM, y/o por terceros proveedores de Servicios y Contenidos. PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM tendrá el derecho a negar, restringir o condicionar al Usuario el acceso al Portal, total o parcialmente, a su entera discreción, así como a modificar los Servicios y Contenidos del Portal en cualquier momento y sin necesidad de previo aviso.
                        <br/><br/>
                        El Usuario reconoce que no todos los Servicios y Contenidos están disponibles en todas las áreas geográficas y que algunos Servicios y Contenidos pueden ser utilizados solamente con posterioridad a su contratación, activación o registro previo por el Usuario y/o mediante el pago de una comisión, según se indique en las condiciones de contratación que se establezcan en la documentación respectiva. PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM no garantiza la disponibilidad y continuidad de la operación del Portal y de los Servicios y Contenidos, ni la utilidad del Portal o los Servicios y Contenidos en relación con cualquier actividad específica, independientemente del medio de acceso que utilice el Usuario incluido la telefonía móvil. PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM no será responsable por daño o pérdida alguna de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación de la Página y/o de los Servicios y Contenidos.
                        <br/><br/>

                        El aprovechamiento de los Servicios y Contenidos del Portal es exclusiva responsabilidad del Usuario, quien en todo caso deberá servirse de ellos acorde a las funcionalidades permitidas en el propio Portal y a los usos autorizados en los presentes Términos y Condiciones de Uso y Privacidad, por lo que el Usuario se obliga a utilizarlos de modo tal que no atenten contra las normas de uso y convivencia en Internet, las leyes de los Estados Unidos Mexicanos y la legislación vigente en el país en que el Usuario se encuentre al usarlos, las buenas costumbres, la dignidad de la persona y los derechos de terceros. El Portal es para el uso individual del Usuario por lo que no podrá comercializar de manera alguna los Servicios y Contenidos.

                    </span>
                    <span className="heading-2">
                    1.1 RESTRICCIONES
                    </span>
                    <span className="text">
                    El Usuario no tiene el derecho de colocar híper ligas dentro del Portal, a utilizar las ligas del Portal, ni el derecho de colocar o utilizar los Servicios y Contenidos en sitios o páginas propias o de terceros sin autorización previa y por escrito del PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM. Asimismo, el Usuario no tendrá el derecho de limitar o impedir a cualquier otro Usuario el uso del Portal.
                    <br/><br/>
                    </span>
                    <span className="heading-2">
                    2. PROPIEDAD INTELECTUAL
                    </span>
                    <span className="text">
                    Los derechos de propiedad intelectual respecto de los Servicios y Contenidos y los signos distintivos y dominios de las Páginas o el Portal, así como los derechos de uso y explotación de los mismos, incluyendo su divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM. El Usuario no adquiere ningún derecho de propiedad intelectual por el simple uso de los Servicios y Contenidos de la Página y en ningún momento dicho uso será considerado como una autorización o licencia para utilizar los Servicios y Contenidos con fines distintos a los que se contemplan en los presentes Términos y Condiciones de Uso y Privacidad y a los contratos respectivos.<br/><br/>

                    </span>
                    <span className="heading-2">
                        3. PROPIEDAD INTELECTUAL DE TERCEROS
                    </span>
                    <span className="text">
                        El Usuario acuerda que las disposiciones que se establecen en el inciso 2 anterior respecto de la titularidad de los derechos de PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM también son aplicables a los derechos de terceros respecto de los Servicios y Contenidos de las Páginas, dominios o información presentada o vinculada al Portal.<br/><br/>
                    </span>
                    <span className="heading-2">
                    4. CALIDAD DE LOS SERVICIOS Y CONTENIDOS
                    </span>
                    <span className="text">
                        Ni PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM, ni sus proveedores o socios comerciales serán responsables de cualquier daño o perjuicio que sufra el Usuario a consecuencia de inexactitudes, consultas realizadas, asesorías, errores tipográficos y cambios o mejoras que se realicen periódicamente a los Servicios y Contenidos. Las recomendaciones y consejos obtenidos a través del Portal son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones personales ni profesionales. Para ello se debe consultar a un profesional apropiado que pueda asesorar al Usuario de acuerdo con sus necesidades específicas.
                    </span>
                    <span className="heading-2">
                    5. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS
                    </span>
                    <span className="text">
                    El hecho de que se ofrezca información en el Portal o en otros ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación por parte del PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM respecto dicha información, bienes y/o servicios. La disponibilidad de bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM. En vista de lo anterior, PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Portal.
                    <br/><br/>

                    Respecto de los Servicios y Contenidos que prestan terceros dentro o mediante enlaces a la Página (tales como ligas, banners y botones), el PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM se limita exclusivamente, para conveniencia del Usuario, a: (i) informar al Usuario sobre los mismos, y (ii) a proporcionar un medio para poner en contacto al Usuario con proveedores o vendedores. Los productos y/o servicios que se comercializan dentro del Portal y/o en los sitios de terceros enlazados son suministrados por comerciantes independientes al PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM. No existe ningún tipo de relación laboral, asociación o sociedad, entre el PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM y dichos terceros. Toda asesoría, consejo, declaración, información y contenido de las páginas de terceros enlazadas o dentro de la Página representan las opiniones y juicios de dicho tercero, consecuentemente, el PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM no será responsable de ningún daño o perjuicio que sufra el Usuario a consecuencia de los mismos.<br/><br/>

                    </span>
                    <span className="heading-2">
                    6. CONFIDENCIALIDAD
                    </span>
                    <span className="text">
                    El PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM se obliga a mantener confidencial la información que reciba del Usuario que tenga dicho carácter conforme a las disposiciones legales aplicables, en los Estados Unidos Mexicanos. El PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM no asume ninguna obligación de mantener confidencial cualquier otra información que el Usuario le proporcione, ya sea al inscribirse al Portal o en cualquier otro momento posterior, incluyendo aquella información que el Usuario proporcione a través de boletines, pizarras o plática en línea (chats); así como, la información que obtenga el PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM a través de las Cookies que se describen en inciso 8.<br/><br/>

                    </span>
                    <span className="heading-2">
                    7. USO DE LA INFORMACIÓN NO CONFIDENCIAL O INDIVIDUAL
                    </span>
                    <span className="text">
                    Mediante el uso del Portal, el Usuario autoriza al PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM a utilizar, publicar, reproducir, divulgar, comunicar públicamente y transmitir la información no confidencial o no individual, en términos de lo establecido en el artículo 109 de la Ley Federal de los Derechos de Autor y de la fracción I, del artículo 76 bis de la Ley Federal de Protección al Consumidor.<br/><br/>

                    </span>
                    <span className="heading-2">
                    8. AVISO DE PRIVACIDAD DE DATOS PERSONALES
                    </span>
                    <span className="text">
                    Toda la información que PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM recabe del Usuario es tratada con absoluta confidencialidad conforme las disposiciones legales aplicables.<br/>
                    Para conocer mayor información de la protección de sus datos personales acuda a la siguiente liga: 
                    <a href="http://www.homefiesta.com/privacidad_" title="Home Fiesta - Privacidad" target="_blank"  className="pink-text">
                        http://www.homefiesta.com/privacidad_
                    </a>

                    <br/><br/>

                    </span>
                    <span className="heading-2">
                    9. CLAVES DE ACCESO
                    </span>
                    <span className="text">
                    En todo momento, el Usuario es el responsable único y final de mantener en secreto su clave de acceso con la cual tenga acceso a ciertos Servicios y Contenidos del Portal; así como a las páginas de terceros.

                    <br/><br/>

                    </span>
                    <span className="heading-2">
                    10. MODIFICACIONES
                    </span>
                    <span className="text">
                    PORTALES THE HOME FIESTA.COM  Y VIVA LAS FIESTAS.COM tendrá el derecho de modificar en cualquier momento los Términos y Condiciones de Uso y Privacidad. En consecuencia, el Usuario debe leer atentamente los Términos y Condiciones de Uso y Privacidad cada vez que pretenda utilizar el Portal. Ciertos Servicios y Contenidos ofrecidos a los Usuarios en y/o a través del Portal están sujetos a condiciones particulares propias que sustituyen, completan y/o modifican los Términos y Condiciones de Uso y Privacidad (en lo sucesivo, las "Condiciones Particulares"). Consiguientemente, el Usuario también debe leer atentamente las correspondientes Condiciones Particulares antes de acceder a cualquiera de los Servicios y Contenidos.
                    <br/><br/>

                    De conformidad con la legislación aplicable ciertos servicios requerirán de la instalación de herramientas de protección para la información que se solicite, por lo que servicio será negado en caso de no ser aceptada la instalación requerida.


                    <br/><br/>

                    </span>
                    <span className="heading-2">
                    11. LEYES APLICABLES Y JURISDICCIÓN
                    </span>
                    <span className="text">
                    Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones de Uso y Privacidad, el Usuario está de acuerdo en que serán aplicables las leyes Federales de los Estados Unidos Mexicanos y competentes los tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa.


                    <br/><br/>

                    </span>
                   

                </Col>
            </Row>
        </Container>
    )
}